import React from "react";
import { Link } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/SEO";

const ResourcesPage: React.FC = () => (
    <Layout>
        <SEO title="Resources" />

        <section className="section">
            <div className="container content">
                <h1 className="title is-1">Autism Resources</h1>
                <p>
                    If you would like to submit a resource for this page, please{" "}
                    <Link to="/contact/">contact us</Link>.
                </p>

                <h2 className="title is-2">Technical Training</h2>

                <ul>
                    <li>
                        <a href="https://codeselfstudy.com/">Code Self Study</a>{" "}
                        -- a programming community in Berkeley, California.
                    </li>
                    <li>
                        <Link to="/learn/">Learn How to Code</Link> -- see our
                        recommendations on free programming books and courses.
                    </li>
                </ul>
                <h2 className="title is-2">Autism Social Groups</h2>
                <h3 className="title is-3">Offline</h3>
                <p>
                    Here are some local (San Francisco Bay Area) autism-related
                    meetup groups to check out:
                </p>
                <ul>
                    <li>
                        <a href="https://www.meetup.com/Adult-Autism-Aspergers-Social-Support-Group/">
                            East Bay Adult Autism/Asperger's Social Support
                            Group
                        </a>
                    </li>
                    <li>
                        <a href="https://www.meetup.com/asperger/">
                            San Francisco Adult Asperger Self Help Meetup
                        </a>
                    </li>
                    <li>
                        <a href="https://www.meetup.com/Adult-Aspies-of-San-Francisco/">
                            Adult Aspies of San Francisco
                        </a>
                    </li>
                </ul>
                <h3 className="title is-3">Online</h3>
                <ul>
                    <li>
                        <a href="https://old.reddit.com/r/aspergers/">
                            /r/aspergers
                        </a>{" "}
                        -- see the sidebar for additional autism-related
                        subreddits
                    </li>
                </ul>
                <h2 className="title is-2">Diagnosis and Therapy</h2>
                <p>
                    If you are having an emergency, please see the resources
                    below:
                </p>
                <p>For any kind of crisis:</p>
                <ul>
                    <li>
                        <a href="https://www.crisistextline.org/texting-in">
                            Crisis Text Line
                        </a>{" "}
                        -- &quot;Text HOME to 741741 from anywhere in the United
                        States, anytime, about any type of crisis.&quot;
                    </li>
                </ul>
                <p>Suicide hotlines:</p>
                <ul>
                    <li>1-800-784-2433 (1-800-SUICIDE)</li>
                    <li>1-800-273 TALK (8255)</li>
                </ul>
                <p>Additional resources:</p>
                <ul>
                    <li>
                        <a href="https://old.reddit.com/r/SuicideWatch/wiki/hotlines">
                            Additional hotlines
                        </a>
                    </li>
                    <li>
                        <a href="https://old.reddit.com/r/SWResources/">
                            Suicide Watch Resources
                        </a>
                    </li>
                </ul>
                <p>
                    Please <Link to="/contact/">contact us</Link> if you have
                    additional resources and/or feedback.
                </p>
            </div>
        </section>
    </Layout>
);

export default ResourcesPage;
